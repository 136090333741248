ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.treeRoot {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.treeRoot400 {
    max-height: 400px;
    overflow-x: auto;
    overflow-y: auto;
}

.root {
  align-items: center;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  height: 32px;
  padding-inline-end: 4px;
}

.expandIconWrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  transition: transform linear .1s;
  transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
  transform: rotate(90deg);
}

.labelGridItem {
  padding-inline-start: 4px;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: $color-link-hover;
    cursor: pointer;
  }
}

.draggingSource {
  opacity: 0.3;
}

.dropTarget {
  background-color: #e8f0fe;
}

.placeholderContainer {
  position: relative;
}

.placeholderLine {
  background-color: black;
  height: 2px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%);
}

.offcanvas.offcanvas-end {
  width: 60% !important;
}

.unpublishedItemColor {
  color: #b6b6b6 !important;
}