@import "src/styles/colors.scss";

.card, .docCard {
    box-shadow: 0 2px 10px 0 rgba(180, 180, 180, 0.5);
    border: 0 !important;
    margin-bottom: 0.5rem;
    cursor: pointer;

    &:hover {
        filter: brightness(85%);
        z-index: 1000;
    }

    .body {
        color: $color-white;
        padding: 5px !important;

        .url {
            font-size: 0.8rem;
            font-style: italic;
            font-weight: 300;
        }
    }
}

.card {
    background-color: $color-lightblue !important;
}

.docCard {
    background-color: $color-blue-primary !important;
}
